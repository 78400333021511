<template>
  <v-container fluid>
    <loading-overlay :loading="loading" />
    <v-card tile flat>
      <skeleton-page-heading v-if="data_vendors.length === 0" />
      <v-card-text v-else>
        <breadcrumb-component />
        <v-divider class="tw-mb-5"></v-divider>
        <h2
          :class="
            $vuetify.breakpoint.mdAndUp
              ? 'tw-text-gray-800 tw-text-xl tw-flex tw-font-medium tw-capitalize'
              : 'tw-text-gray-800 tw-text-base tw-flex tw-font-medium tw-capitalize'
          "
        >
          <v-icon class="tw-text-gray-800 tw-mr-2"
            >mdi-account-plus-outline</v-icon
          >
          {{ title }}
        </h2>
        <v-divider class="tw-mt-5"></v-divider>
      </v-card-text>
      <v-card-text>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="12" md="7" lg="7">
            <skeleton-form :count="6" v-if="data_vendors.length === 0" />
            <v-form v-else @submit.prevent="triggerSend">
              <!-- form vendor if admin -->
              <v-autocomplete
                v-if="role === 1"
                dense
                outlined
                item-text="user.name"
                item-value="id"
                label="Vendor"
                :disabled="loading"
                :items="data_vendors"
                :error-messages="errors.vendor"
                v-model="form.vendor_id"
                @change="getPropertyDetails(form.vendor_id)"
              ></v-autocomplete>
              <!-- end -->
              <!-- property for admin -->
              <v-autocomplete
                v-if="
                  role === 1 &&
                    form.vendor_id &&
                    data_property_details.length > 0
                "
                dense
                outlined
                item-text="name"
                item-value="id"
                label="Property Detail"
                :disabled="loading"
                :items="data_property_details"
                :error-messages="errors.property_detail"
                v-model="form.property_detail_id"
              ></v-autocomplete>
              <!-- end -->
              <!-- property for developer -->
              <v-autocomplete
                v-if="role === 2"
                dense
                outlined
                item-text="name"
                item-value="id"
                label="Property Detail"
                :disabled="loading"
                :items="data_property_details"
                :error-messages="errors.property_detail"
                v-model="form.property_detail_id"
              ></v-autocomplete>
              <!-- end -->
              <div v-if="form.property_detail_id">
                <vue-dropzone
                  id="dropzone"
                  ref="myVueDropzone"
                  :options="dropzoneOptions"
                  @vdropzone-complete="onComplete"
                  @vdropzone-error="showError"
                  v-on:vdropzone-sending="sendingEvent"
                  :useCustomSlot="true"
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">
                      Drag and drop to upload content!
                    </h3>
                    <div class="subtitle">
                      ...or click to select a file from your computer
                    </div>
                  </div>
                </vue-dropzone>
                <v-alert
                  dense
                  border="left"
                  type="error"
                  class="mt-2"
                  v-if="errors.image"
                >
                  {{ errors.image }}
                </v-alert>
              </div>
              <div class="tw-text-right tw-mt-2">
                <v-btn
                  depressed
                  class="tw-capitalize tw-tracking-wide"
                  color="primary"
                  max-width="200"
                  width="120"
                  type="submit"
                  :loading="loading"
                  >Submit</v-btn
                >
              </div>
            </v-form>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="5"
            lg="5"
            v-if="$vuetify.breakpoint.mdAndUp"
          >
            <v-skeleton-loader
              v-if="data_vendors.length === 0"
              class="mx-auto"
              width="250"
              type="image"
            ></v-skeleton-loader>
            <v-img
              v-else
              contain
              max-height="250"
              src="https://ik.imagekit.io/5zmdzllq3/undraw_Add_files_re_v09g_AXJ5tiXKN.svg"
            ></v-img>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import form from "@/mixins/form";
import page from "@/mixins/page";
import Swal from "sweetalert2";
import { propertyDetail, vendors } from "@/api/photoDetailProperty";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import BreadcrumbComponent from "../components/BreadcrumbComponent.vue";
import SkeletonPageHeading from "../components/SkeletonPageHeading.vue";
import SkeletonForm from "../components/SkeletonForm.vue";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  components: {
    BreadcrumbComponent,
    LoadingOverlay,
    SkeletonPageHeading,
    SkeletonForm,
    vueDropzone: vue2Dropzone,
  },
  mixins: [form, page],
  data() {
    return {
      form: {
        property_detail_id: "",
        vendor_id: "",
      },
      data_vendors: [],
      data_property_details: [],
      dropzoneOptions: {
        autoProcessQueue: false,
        addRemoveLinks: true,
        maxFilesize: 2,
        maxFiles: 5,
        acceptedFiles: "image/*,.svg",
        headers: {
          Authorization: "Bearer " + this.$store.state.auth.token,
          Accept: "application/json",
        },
        url: `${process.env.VUE_APP_API}/photo-detail-properties`,
      },
    };
  },
  async created() {
    this.getVendors();
    this.getPropertyDetails();
  },
  methods: {
    async getVendors() {
      this.vendorId === null
        ? (this.form.vendor_id = "")
        : (this.form.vendor_id = this.vendorId);
      const {
        data: { data },
      } = await vendors();
      this.data_vendors = data;
    },
    async getPropertyDetails(id) {
      if (typeof id === "undefined") {
        if (this.vendorId === null) {
          this.data_property_details = [];
        } else {
          const {
            data: { data },
          } = await propertyDetail(this.vendorId);
          this.data_property_details = data;
        }
      } else {
        const {
          data: { data },
        } = await propertyDetail(id);
        if (data.length === 0) {
          this.data_property_details = [];
          Swal.fire({
            icon: "error",
            title: "Data terkait tidak di temukan",
            timer: 1000,
          });
        } else {
          this.data_property_details = data;
        }
      }
    },
    triggerSend() {
      if (!this.form.vendor_id) {
        this.errors = {
          vendor: "Vendor tidak boleh kosong",
        };
      } else if (!this.form.property_detail_id) {
        this.errors = {
          property_detail: "Property detail tidak boleh kosong",
        };
      } else if (this.$refs.myVueDropzone.getQueuedFiles().length === 0) {
        this.errors = {
          image: "Upload gambar harus di isi",
        };
      } else {
        this.$refs.myVueDropzone.processQueue();
      }
    },
    sendingEvent(file, xhr, formData) {
      formData.append("property_detail", this.form.property_detail_id);
      formData.append("vendor", this.form.vendor_id);
    },
    async onComplete(response) {
      if (response.status === "success") {
        await Swal.fire({
          icon: "success",
          title: "Berhasil upload",
          timer: 1500,
        });

        this.$router.push({ name: "photo-detail-property" });
      }
    },
    showError(file, message, xhr) {
      const errorMessage = JSON.parse(xhr.response).errors;
      const elements = document.querySelectorAll(".dz-error-message span");
      const lastElement = elements[elements.length - 1];
      lastElement.textContent = errorMessage;

      if (message.statusCode === 422) {
        this.errors = {
          image: message.errors,
        };
      } else {
        Swal.fire({
          icon: "error",
          title: message.message,
          timer: 1500,
        });
      }
    },
  },
  computed: {
    role() {
      return parseInt(this.$store.state.auth.profile.role_id);
    },
    vendorId() {
      return parseInt(this.$store.state.auth.profile.vendor_id);
    },
  },
};
</script>

<style>
#dropzone .dz-preview .dz-image > img {
  height: 100px !important;
}

.vue-dropzone > .dz-preview .dz-remove {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.dropzone .dz-preview .dz-error-message {
  padding: 0.5em 0.7em !important;
  font-size: 11px !important;
}
</style>
