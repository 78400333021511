import api from "./api";

const endPoint = "/photo-detail-properties";

export const detail = (id) =>
  api
    .get(`${endPoint}/${id}`)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });

export const post = async (payload) => {
  const formData = new FormData();
  formData.append("property_detail", payload.property_detail_id);
  formData.append("image", payload.image);

  await api.post(`${endPoint}`, formData);
};

export const update = async (payload, optionalData) => {
  const formData = new FormData();
  formData.append("_method", "put");
  formData.append("id", payload.id);
  formData.append("property_detail", payload.property_detail_id);
  formData.append("image", optionalData.image);

  await api.post(`${endPoint}/${payload.id}`, formData);
};

export const vendors = () =>
  api
    .get("/vendors/all")
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });

export const propertyDetail = (id) =>
  api
    .get(`/property-details/by-vendor/${id}`)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });


export const approved = (id) =>
  api
    .get(`${endPoint}/approved/${id}`)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });

export const rejected = (id) =>
  api
    .get(`${endPoint}/rejected/${id}`)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });
